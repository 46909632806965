import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ReactGA from "react-ga";
import Twitter from "../images/icons/twitter.png";
import YouTube from "../images/icons/youtube.png";
import LinkedIn from "../images/icons/linkedin.png";
import Github from "../images/icons/github-logo.png";
import HeadShot from "../images/head_shot_3.jpg";
import { Link } from 'gatsby';

// Icon Pack: https://www.flaticon.com/packs/social-media-icons
// Icon Pack: https://www.flaticon.com/free-icon/github-logo_25231

class IndexPage extends React.Component {

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
		// this.asyncScript('https://gumroad.com/js/gumroad.js');
	}

	asyncScript = (scriptPath) => {

		const script = document.createElement('script');
		script.src = scriptPath;
		script.async = true;
		document.body.appendChild(script);
	
	}

	render() {
		return (
			<Layout>
				<SEO
					title="Quinston Pimenta @QuinstonPimenta"
					keywords={[
						`Product Development, Entrepreneurship`,
						`Product Development, Entrepreneurship india`,
						`Product Development, Entrepreneurship pune`
					]}
				/>
				<Navigation />
				<div style={{ marginTop: 20 }} />
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: "2rem"
					}}
				>
					<Row>
						<Col>
							<Image
								className="card-2"
								src={HeadShot}
								roundedCircle
								height={180}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<h1 style={{ marginBottom: 0 }}>Quinston Pimenta</h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>Co-founder at <a href="https://timebolt.io" target={'_blank'}>TimeBolt.io</a></p>
						</Col>
					</Row>
					<div style={{ marginTop: 20 }} />
					<Row>
						{/* <Col>
							<a
								href="https://youtube.com/quinstonpimenta"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Image src={YouTube} style={{ marginBottom: 0 }} height="50" />
							</a>
						</Col> */}
						<Col>
							<a
								href="https://youtube.com/quinstonpimenta"
								target="_blank"
								rel="noopener noreferrer"
							>
								YouTube
							</a>
						</Col>
						<Col>
							<a
								href="https://twitter.com/QuinstonPimenta"
								target="_blank"
								rel="noopener noreferrer"
							>
								Twitter
							</a>
						</Col>
						<Col>
							<a
								href="https://www.linkedin.com/in/quinston/"
								target="_blank"
								rel="noopener noreferrer"
							>
								LinkedIn
							</a>
						</Col>
						{/* <Col>
							<a
								href="https://www.github.com/graphoarty/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Image src={Github} style={{ marginBottom: 0 }} height="50" />
							</a>
						</Col> */}
					</Row>
					<div style={{ marginTop: 100 }} />
				</Container>
			</Layout>
		);
	}
}

export default IndexPage;
